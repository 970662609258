import axios from "axios";
import helper from "./helper";
const http = axios.create();
http.defaults.baseURL = process.env.REACT_APP_API_URL;
if (localStorage.getItem("db")) {
  const storage = JSON.parse(helper.decrypt(localStorage.getItem("db")));
  const { access_token } = storage;
  http.defaults.headers.common.Authorization = `Bearer ${access_token}`;
}
http.defaults.headers.post["Content-Type"] = "application/json";
http.defaults.headers.post["Accept"] = "application/json";

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        localStorage.removeItem("db");
        delete http.defaults.headers.common.Authorization;
        window.location.reload(true);
      }
    }
    return Promise.reject(error);
  }
);

export default http;
