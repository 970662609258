/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import CryptoJS from "crypto-js";
export default {
  decrypt(str) {
    const dec = CryptoJS.AES.decrypt(
      str,
      CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_KEY),
      {
        iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_KEY), // parse the IV
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );
    return dec.toString(CryptoJS.enc.Utf8);
  },
  encrypt(str) {
    const enc = CryptoJS.AES.encrypt(
      str,
      CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_KEY),
      {
        iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_KEY), // parse the IV
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );
    return enc.toString();
  },
  isAuthenticated() {
    if (localStorage.getItem("db")) {
      const dirtyStorage = JSON.parse(this.decrypt(localStorage.getItem("db")));
      if (dirtyStorage?.access_token) {
        return dirtyStorage;
      }
    }
    return false;
  },
  getCart() {
    if (localStorage.getItem("carts")) {
      const dirtyStorage = JSON.parse(
        this.decrypt(localStorage.getItem("carts"))
      );
      return dirtyStorage;
    }
    return [];
  },
  getProfile() {
    if (localStorage.getItem("profile")) {
      const dirtyStorage = this.decrypt(localStorage.getItem("profile"));

      return dirtyStorage;
    }
    return null;
  },
  truncString(str, max, add) {
    add = add || "...";
    return typeof str === "string" && str.length > max
      ? str.substring(0, max) + add
      : str;
  },
  formatDate(date) {
    var d = date,
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },
  formatRupiah(angka, prefix) {
    var number_string = angka
        ? angka
            .toString()
            .replace(/[^,\d]/g, "")
            .toString()
        : "",
      split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      let separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
  },
  formatN(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  },
  /* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
  isHTML(str) {
    var a = document.createElement("div");
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType === 1) return true;
    }

    return false;
  },
};
