import React, { useEffect, useState } from "react";
import Spin from "antd/es/spin";
import notification from "antd/es/notification";
import Router from "./router";
import http from "../utils/http";

function Main() {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  useEffect(() => {
    http.interceptors.request.use(
      (config) => {
        setOpenBackdrop(true);
        return config;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          notification.error({
            message: error.response
              ? `${error.response.data.message}`
              : "Something wrong with our server, please try again later.",
            placement: "bottomRight",
            duration: 5,
          });
        }
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        if (response?.config?.method !== "get") {
          if (response?.data?.message) {
            notification.success({
              message: response.data.message,
              placement: "bottomRight",
              duration: 5,
            });
          }
        }
        setOpenBackdrop(false);
        return response;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          notification.error({
            message: error.response
              ? `${error.response.data.message}`
              : "Something wrong with our server, please try again later.",
            placement: "bottomRight",
            duration: 5,
          });
        }
        return Promise.reject(error);
      }
    );
  }, []);
  return (
    <>
      <Spin spinning={openBackdrop} tip="Please wait.." className="h-full">
        <Router />
      </Spin>
    </>
  );
}

export default Main;
