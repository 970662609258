import Result from "antd/es/result";
import Button from "antd/es/button";
function BlankCacheComponent(props) {
  const { emptyCacheStorage } = props;
  return (
    <Result
      status="warning"
      title="Update Available!"
      extra={
        <Button
          type="primary"
          key="console"
          onClick={() => emptyCacheStorage()}
        >
          Update Version
        </Button>
      }
    />
  );
}
export default BlankCacheComponent;
