import React, { lazy, Suspense } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import helper from "../utils/helper";

const LoginPage = lazy(() => import("../pages/LoginPage"));
const ForgotPage = lazy(() => import("../pages/ForgotPage"));
const ResetPage = lazy(() => import("../pages/ResetPage"));

const DashboardPage = lazy(() => import("../pages/DashboardPage"));
const ProductPage = lazy(() => import("../pages/ProductPage"));
const BlankNotAccess = lazy(() => import("../components/Blank/BlankNotAccess"));

const MasterPage = lazy(() => import("../pages/MasterPage"));
const HomePage = lazy(() => import("../pages/HomePage"));
const ShopPage = lazy(() => import("../pages/ShopPage"));
const AboutUsPage = lazy(() => import("../pages/AboutUsPage"));
const HowToShopPage = lazy(() => import("../pages/HowToShopPage"));
const DetailProductPage = lazy(() => import("../pages/DetailProductPage"));
const CheckoutPage = lazy(() => import("../pages/CheckoutPage"));
const SettlementPage = lazy(() => import("../pages/SettlementPage"));
const MyOrderPage = lazy(() => import("../pages/MyOrderPage"));

const PageLoader = () => (
  <div id="loader">
    <div className="loadingio-spinner-rolling-3kjtqyynjid">
      <div className="ldio-ucwibazh2i9">
        <div></div>
      </div>
    </div>
  </div>
);
const PublicRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const PrivateRoute = ({ title, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function Router(props) {
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <Route exact path="/home" render={(props) => <HomePage {...props} />} />
        <Route exact path="/shop" render={(props) => <ShopPage {...props} />} />
        <Route
          exact
          path="/my-order"
          render={(props) => <MyOrderPage {...props} />}
        />
        <Route
          exact
          path="/detailproduct"
          render={(props) => <DetailProductPage {...props} />}
        />
        <Route
          exact
          path="/checkout"
          render={(props) => <CheckoutPage {...props} />}
        />
        <Route
          exact
          path="/about"
          render={(props) => <AboutUsPage {...props} />}
        />
        <Route
          exact
          path="/howtoshop"
          render={(props) => <HowToShopPage {...props} />}
        />
        <Route
          exact
          path="/order"
          render={(props) => <SettlementPage {...props} />}
        />
        <PublicRoute path="/login">
          <LoginPage />
        </PublicRoute>
        <PublicRoute path="/forgot">
          <ForgotPage />
        </PublicRoute>
        <Route
          exact
          path="/reset/:token?"
          render={(props) =>
            helper.isAuthenticated() ? (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: props.location },
                }}
              />
            ) : props.match.params.token ? (
              <ResetPage token={props.match.params.token} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />

        <PrivateRoute path="/dashboard">
          <DashboardPage />
        </PrivateRoute>

        <PrivateRoute path="/formproduct">
          <ProductPage {...props} />
        </PrivateRoute>

        <PrivateRoute path={"*"}>
          {helper.isAuthenticated() ? <MasterPage /> : <BlankNotAccess />}
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
}
export default withRouter(Router);
